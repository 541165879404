import React, { useState, useEffect } from "react";
import Flashcard from "../components/FlashCard";
import styled from "styled-components";
import Nav from "../components/Nav";

function App() {
  const [score, setScore] = useState(0);
  const [operation, setOperation] = useState("");
  const [question, setQuestion] = useState(null);
  const [correct, setCorrect] = useState(false);
  const [incorrect, setIncorrect] = useState(false);
  const initialTimerDuration = 20;
  const [timerDuration, setTimerDuration] = useState(initialTimerDuration);
  const [timeLeft, setTimeLeft] = useState(null);
  const [timerEnabled, setTimerEnabled] = useState(false);
  const [highScore, setHighScore] = useState(() => {
    // Get the high score from local storage, or default to 0 if it doesn't exist
    const savedHighScore = localStorage.getItem("highScore");
    return savedHighScore ? Number(savedHighScore) : 0;
  });

  function generateQuestion(operation) {
    let num1 = Math.floor(Math.random() * 10);
    let num2 = Math.floor(Math.random() * 10);
    let answer;

    switch (operation) {
      case "addition":
        answer = num1 + num2;
        break;
      case "subtraction":
        if (num1 < num2) [num1, num2] = [num2, num1];
        answer = num1 - num2;
        break;
      case "multiplication":
        answer = num1 * num2;
        break;
      case "division":
        num2 = Math.floor(Math.random() * 9) + 1; // Divisor between 1 and 9
        const quotient = Math.floor(Math.random() * 10); // Result of division
        num1 = num2 * quotient; // Dividend that is divisible by num2
        answer = quotient;
        break;
      default:
        answer = num1 + num2;
    }

    return { num1, num2, answer, operation };
  }

  const handleOperationChange = (operation) => {
    setOperation(operation);
    setQuestion(generateQuestion(operation));
    resetTimer(); // Reset timer when operation changes
  };

  const handleCorrectAnswer = () => {
    let newScore;
    if (timerEnabled) {
      const firstThirdThreshold = timerDuration * (2 / 3);
      const secondThirdThreshold = timerDuration * (1 / 3);

      if (timeLeft > firstThirdThreshold) {
        // If score is initially 0, add a minimum increment instead of doubling
        newScore = score === 0 ? score + 10 : score * 2;
      } else if (timeLeft > secondThirdThreshold) {
        newScore = score + 5;
      } else {
        newScore = score + 1;
      }

      // Check the updated score and set timer duration if needed
      if (newScore >= 1000) {
        setTimerDuration(5);
      } else if (newScore >= 50) {
        setTimerDuration(10);
      }
      setScore(newScore);
    } else {
      newScore = score + 1;
    }
    // Update highScore if the new score is higher and save to local storage
    setHighScore((prevHighScore) => {
      const updatedHighScore = Math.max(prevHighScore, newScore);
      localStorage.setItem("highScore", updatedHighScore); // Save to local storage
      return updatedHighScore;
    });
    setScore(newScore);
    setQuestion(generateQuestion(operation));
    resetTimer();
    setCorrect(true);

    setTimeout(() => {
      setCorrect(false);
    }, 1000);
  };

  const handleIncorrectAnswer = () => {
    setQuestion(generateQuestion(operation));
    resetTimer();
    setIncorrect(true);
    setTimerDuration(initialTimerDuration);
    if (score > 0) {
      setScore(0);
    }

    // Use setTimeout to reset success after 1 second, not setInterval
    setTimeout(() => {
      setIncorrect(false);
    }, 1000);
  };

  const resetTimer = () => setTimeLeft(timerDuration); // Reset timer to 10 seconds

  const getTimerColor = () => {
    const start = 10; // Starting time
    const end = 3; // Time to reach red
    const range = start - end; // Range for transition

    if (timeLeft > end) {
      const green = Math.floor(((timeLeft - end) / range) * 255);
      const red = 255 - green;
      return `rgb(${red}, ${green}, 0)`;
    } else {
      return `rgb(255, 0, 0)`;
    }
  };

  useEffect(() => {
    if (!timerEnabled) {
      return;
    }

    if (timeLeft === 0) {
      handleIncorrectAnswer(); // Move to the next question if time runs out
      return;
    }
    const timer = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timer); // Cleanup interval on component unmount
  }, [timeLeft, timerEnabled]);

  useEffect(() => {
    if (timerEnabled) {
      setTimeLeft(timerDuration);
    }
  }, [timerEnabled]);

  useEffect(() => {
    setScore(0);
  }, [operation]);

  return (
    <>
      <Nav />
      <PageWrapper
        className={`${correct ? "correct" : ""} ${
          incorrect ? "incorrect" : ""
        }`}
      >
        <div className="wrap">
          {operation && (
            <>
              <p id="score">Score: {score}</p>
              {timerEnabled ? (
                <Timer
                  color={getTimerColor()}
                  id="timer"
                  onClick={() => setTimerEnabled(false)}
                >
                  {timeLeft}
                </Timer>
              ) : (
                <Timer
                  color="#ff5555"
                  id="timer"
                  onClick={() => setTimerEnabled(true)}
                >
                  Start Timer
                </Timer>
              )}
              <Flashcard
                question={question}
                onCorrect={handleCorrectAnswer}
                onIncorrect={handleIncorrectAnswer}
              />
            </>
          )}
          {!operation && <h1 id="start">What to work on?</h1>}
          <div className={`operation-wrap ${!operation ? "start" : ""}`}>
            <div id="operation-icons">
              <p
                id="addition"
                className={`operator-icon ${
                  operation === "addition" ? "active" : ""
                }`}
                onClick={() => handleOperationChange("addition")}
              >
                +
              </p>
              <p
                id="subtraction"
                className={`operator-icon ${
                  operation === "subtraction" ? "active" : ""
                }`}
                onClick={() => handleOperationChange("subtraction")}
              >
                -
              </p>
              <p
                id="multiplication"
                className={`operator-icon ${
                  operation === "multiplication" ? "active" : ""
                }`}
                onClick={() => handleOperationChange("multiplication")}
              >
                ×
              </p>
              <p
                id="division"
                className={`operator-icon ${
                  operation === "division" ? "active" : ""
                }`}
                onClick={() => handleOperationChange("division")}
              >
                ÷
              </p>
            </div>
          </div>
        </div>
        <p id="high-score">High Score: {highScore}</p>
      </PageWrapper>
    </>
  );
}

const PageWrapper = styled.div`
  text-align: center;
  margin: 20px;

  @media only screen and (max-width: 900px) {
    padding: 0 20px;
  }

  #start {
    margin: 50px 0;
  }

  .wrap {
    position: relative;
    width: 400px;
    max-width: 100%;
    margin: 0 auto;
    padding: 20px 0 50px 0;
    border-radius: 15px;
    background-color: #f6f6f6;
  }

  .flashcard {
    background-color: #f6f6f6;
    padding: 20px 0 50px 0;
  }

  h1 {
    margin-top: 20px;
  }

  #score {
    font-size: 30px;
    margin: 10px 0;
  }

  select {
    height: 30px;
    margin: 20px 0 50px 0;

    @media only screen and (max-width: 900px) {
      margin: 20px 0;
    }
  }
  .operation-wrap {
    width: 100%;
    background-color: #f6f6f6;
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 20px;
    border-radius: 15px;

    &.start {
      background-color: transparent;
    }
  }
  #operation-icons {
    margin: 0 auto;
    display: flex;
    border: 1px solid #a5a5a5;
    width: fit-content;
    border-radius: 10px;
    background-color: #f6f6f6;

    .operator-icon {
      color: #000;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      margin: 0;
      border-radius: 5px;
      font-size: 31px;

      &.active {
        background: #ffb85c;
        color: #fff;
      }
    }
  }

  &.correct {
    input {
      background-color: #c4f3aa;
    }
  }
  &.incorrect {
    input {
      background-color: #ff5555;
    }
  }

  #high-score {
    text-align: center;
    width: fit-content;
    margin: 20px auto 0 auto;
    font-weight: 400;
  }
`;

const Timer = styled.p`
  font-size: 25px;
  font-weight: 700;
  color: ${(props) => props.color};
  background-color: #f6f6f6;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  text-align: center;
  padding: 20px 0 0 0;
`;

export default App;
