// const questionsArray = [
//   { id: 1, prompt: "One person giving the other a lap dance" },
//   { id: 2, prompt: "Two people make out" },
//   { id: 3, prompt: "One person spanking the other" },
//   { id: 4, prompt: "Two people be in bed together" },
//   { id: 5, prompt: "One person unzipping the other’s pants" },
//   { id: 6, prompt: "Two people shower together" },
//   { id: 7, prompt: "One person undress the other" },
//   { id: 8, prompt: "Two people kiss passionately" },
//   { id: 9, prompt: "One person tying the other to a chair" },
//   { id: 10, prompt: "Two people skinny dip" },
//   { id: 11, prompt: "One person grope the other" },
//   { id: 12, prompt: "Two people strip" },
//   { id: 13, prompt: "One person kiss the other’s neck" },
//   { id: 14, prompt: "Two people make out in a car" },
//   { id: 15, prompt: "One person give the other a hickey" },
//   { id: 16, prompt: "Two people get undressed together" },
//   { id: 17, prompt: "One person grab the other’s butt" },
//   { id: 18, prompt: "Two people be in a compromising position" },
//   { id: 19, prompt: "One person striptease for the other" },
//   { id: 20, prompt: "Two people cuddle in bed" },
//   { id: 21, prompt: "One person kiss the other’s stomach" },
//   { id: 22, prompt: "Two people be caught in the act" },
//   { id: 23, prompt: "One person unzip the other’s dress" },
//   { id: 24, prompt: "Two people be caught sneaking out" },
//   { id: 25, prompt: "One person pulling the other’s shirt off" },
//   { id: 26, prompt: "Two people rub lotion on each other" },
//   { id: 27, prompt: "One person slap the other’s butt" },
//   { id: 28, prompt: "Two people share a kiss under mistletoe" },
//   { id: 29, prompt: "One person giving the other a sensual massage" },
//   { id: 30, prompt: "Two people kiss on the neck" },
//   { id: 31, prompt: "One person crawl into bed with the other" },
//   { id: 32, prompt: "Two people take off each other’s clothes" },
//   { id: 33, prompt: "One person give the other a foot massage" },
//   { id: 34, prompt: "Two people take a bubble bath together" },
//   { id: 35, prompt: "One person make out with the other" },
//   { id: 36, prompt: "Two people hide under a blanket together" },
//   { id: 37, prompt: "One person remove the other’s bra" },
//   { id: 38, prompt: "Two people cuddle on a couch" },
//   {
//     id: 39,
//     prompt: "One person apply sunscreen on the other’s back",
//   },
//   { id: 40, prompt: "Two people share a tight hug" },
//   { id: 41, prompt: "One person give the other a hickey" },
//   { id: 42, prompt: "Two people kiss while lying down" },
//   { id: 43, prompt: "One person lift the other’s shirt" },
//   { id: 44, prompt: "Two people be caught making out" },
//   {
//     id: 45,
//     prompt: "One person whisper something naughty in the other’s ear",
//   },
//   {
//     id: 46,
//     prompt: "Two people be caught in a compromising position",
//   },
//   { id: 47, prompt: "One person grab the other’s chest" },
//   { id: 48, prompt: "Two people kiss each other’s hands" },
//   {
//     id: 49,
//     prompt: "One person give the other a passionate kiss",
//   },
//   { id: 50, prompt: "Two people strip down to their underwear" },
//   { id: 51, prompt: "One person kiss the other’s neck" },
//   { id: 52, prompt: "Two people share a single blanket" },
//   { id: 53, prompt: "One person cuddle with the other" },
//   { id: 54, prompt: "Two people share a bed" },
//   { id: 55, prompt: "One person unzip the other’s pants" },
//   { id: 56, prompt: "Two people rub oil on each other" },
//   { id: 57, prompt: "One person lick the other’s ear" },
//   { id: 58, prompt: "Two people shower together" },
//   { id: 59, prompt: "One person kiss the other’s stomach" },
//   { id: 60, prompt: "Two people take off each other’s pants" },
//   { id: 61, prompt: "One person tie the other up" },
//   { id: 62, prompt: "Two people share a kiss in the rain" },
//   { id: 63, prompt: "One person pin the other against a wall" },
//   { id: 64, prompt: "Two people be caught fooling around" },
//   { id: 65, prompt: "One person grope the other" },
//   { id: 66, prompt: "Two people strip for each other" },
//   { id: 67, prompt: "One person kiss the other’s hand" },
//   { id: 68, prompt: "Two people make out on a couch" },
//   { id: 69, prompt: "One person give the other a lap dance" },
//   { id: 70, prompt: "Two people make out in the dark" },
//   { id: 71, prompt: "One person undress the other" },
//   { id: 72, prompt: "Two people cuddle in bed" },
//   { id: 73, prompt: "One person kiss the other’s forehead" },
//   { id: 74, prompt: "Two people strip down to their underwear" },
//   { id: 75, prompt: "One person put on the other’s bra" },
//   { id: 76, prompt: "Two people hide under the covers together" },
//   { id: 77, prompt: "One person remove the other’s bra" },
//   { id: 78, prompt: "Two people share a single bed" },
//   { id: 79, prompt: "One person kiss the other’s chest" },
//   { id: 80, prompt: "Two people rub each other’s feet" },
//   { id: 81, prompt: "One person grab the other’s butt" },
//   { id: 82, prompt: "Two people take a steamy shower together" },
//   {
//     id: 83,
//     prompt: "One person give the other a passionate kiss",
//   },
//   { id: 84, prompt: "Two people share a secret kiss" },
//   { id: 85, prompt: "One person tie the other to a bed" },
//   { id: 86, prompt: "Two people make out in a tight space" },
//   { id: 87, prompt: "One person grope the other’s chest" },
//   { id: 88, prompt: "Two people sneak a kiss" },
// ];
// export default questionsArray;
const questionsArray = [
  { id: 1, prompt: "Passing a hot dog back and forth" },
  { id: 2, prompt: "One person eating a banana while the other watches" },
  { id: 3, prompt: "Two people trying to fit into the same pair of pants" },
  { id: 4, prompt: "One person applying lotion on the other’s back" },
  { id: 5, prompt: "Two people pulling on opposite ends of a rope" },
  { id: 6, prompt: "One person helping the other zip up a dress" },
  { id: 7, prompt: "One person tying the other’s tie" },
  { id: 8, prompt: "Two people arm wrestling over a table" },
  { id: 9, prompt: "One person popping a balloon in front of the other" },
  { id: 10, prompt: "Two people sharing a piece of spaghetti" },
  { id: 11, prompt: "One person pulling the other into a close hug" },
  { id: 12, prompt: "Two people trying to fit under a small blanket" },
  { id: 13, prompt: "One person giving the other a piggyback ride" },
  { id: 14, prompt: "One person massaging the other’s shoulders" },
  { id: 15, prompt: "Two people trying to high-five while holding drinks" },
  { id: 16, prompt: "One person wrapping the other in a towel" },
  { id: 17, prompt: "Two people slow dance closely" },
  { id: 18, prompt: "One person feeding the other a piece of cake" },
  { id: 19, prompt: "Two people trying to balance on one leg together" },
  { id: 20, prompt: "One person fixing the other’s collar" },
  { id: 21, prompt: "Two people trying to pass a cherry with their mouths" },
  { id: 22, prompt: "One person helping the other put on a necklace" },
  { id: 23, prompt: "Two people trying to share a chair" },
  { id: 24, prompt: "One person lifting the other in a playful manner" },
  { id: 25, prompt: "Two people blowing out a candle together" },
  { id: 26, prompt: "One person catching the other in an embrace" },
  { id: 27, prompt: "Two people sharing a milkshake with one straw" },
  { id: 28, prompt: "One person adjusting the other’s belt" },
  { id: 29, prompt: "Two people holding hands and twirling" },
  { id: 30, prompt: "One person pushing the other in a shopping cart" },
  { id: 31, prompt: "Two people trying to share a large piece of gum" },
  { id: 32, prompt: "One person placing a hat on the other’s head" },
  { id: 33, prompt: "Two people acting out a handshake that turns into a hug" },
  { id: 34, prompt: "One person helping the other put on a coat" },
  { id: 35, prompt: "Two people share a toothbrush" },
  { id: 36, prompt: "One person trying to lift the other onto a counter" },
  { id: 37, prompt: "Two people trying to catch popcorn in their mouths" },
  { id: 38, prompt: "One person helping the other roll up their sleeves" },
  { id: 39, prompt: "Two people share a lollipop" },
  { id: 40, prompt: "One person trying to button the other’s shirt" },
  {
    id: 41,
    prompt: "Two people passing a balloon between them without using hands",
  },
  { id: 1, prompt: "Passing a hot dog back and forth" },
  { id: 2, prompt: "One person eating a banana while the other watches" },
  { id: 3, prompt: "Two people trying to fit into the same pair of pants" },
  { id: 4, prompt: "One person applying lotion on the other’s back" },
  { id: 5, prompt: "Two people pulling on opposite ends of a rope" },
  { id: 6, prompt: "One person helping the other zip up a dress" },
  { id: 7, prompt: "One person tying the other’s tie" },
  { id: 8, prompt: "Two people arm wrestling over a table" },
  { id: 9, prompt: "One person popping a balloon in front of the other" },
  { id: 10, prompt: "Two people sharing a piece of spaghetti" },
  { id: 11, prompt: "One person pulling the other into a close hug" },
  { id: 12, prompt: "Two people trying to fit under a small blanket" },
  { id: 13, prompt: "One person giving the other a piggyback ride" },
  { id: 14, prompt: "One person massaging the other’s shoulders" },
  { id: 15, prompt: "Two people trying to high-five while holding drinks" },
  { id: 16, prompt: "One person wrapping the other in a towel" },
  { id: 17, prompt: "Two people slow dance closely" },
  { id: 18, prompt: "One person feeding the other a piece of cake" },
  { id: 19, prompt: "One person zipping up the other’s jacket" },
  { id: 20, prompt: "Two people blowing out birthday candles together" },
  { id: 21, prompt: "One person catching the other in their arms" },
  { id: 22, prompt: "Two people sharing a soda with one straw" },
  { id: 23, prompt: "One person adjusting the other’s collar" },
  { id: 24, prompt: "Two people holding hands and skipping" },
  { id: 25, prompt: "One person pushing the other on a swing" },
  { id: 26, prompt: "Two people attempting to share a long scarf" },
  { id: 27, prompt: "One person putting a ring on the other’s finger" },
  { id: 28, prompt: "Two people acting out a proposal" },
  {
    id: 29,
    prompt: "One person blowing up a balloon while the other holds it",
  },
  { id: 30, prompt: "Two people trying to balance on one foot together" },
  { id: 31, prompt: "One person feeding the other a popsicle" },
  { id: 32, prompt: "Two people mimicking a tug-of-war" },
  { id: 33, prompt: "One person whispering a secret in the other’s ear" },
  { id: 34, prompt: "Two people attempting to open a stuck jar" },
  { id: 35, prompt: "One person helping the other put on socks" },
  { id: 36, prompt: "Two people tying each other’s shoelaces" },
  { id: 37, prompt: "One person helping the other button a shirt" },
  { id: 38, prompt: "Two people sharing an ice cream cone" },
  {
    id: 39,
    prompt: "One person catching a falling object while the other points",
  },
  { id: 40, prompt: "Two people row a boat together" },
  { id: 41, prompt: "One person tying the other’s apron" },
  { id: 42, prompt: "Two people sharing a large sandwich" },
  { id: 43, prompt: "One person pinning a flower on the other’s shirt" },
  { id: 44, prompt: "Two people sharing a tight hug" },
  { id: 45, prompt: "One person pulling the other into a dance" },
  { id: 46, prompt: "Two people trying to squeeze through a doorway together" },
  { id: 47, prompt: "One person blowing a kiss to the other" },
  { id: 48, prompt: "Two people share a sleeping bag" },
  { id: 49, prompt: "One person putting a necklace on the other" },
  { id: 50, prompt: "Two people playing with a hula hoop together" },
  { id: 51, prompt: "One person brushing the other’s hair" },
  { id: 52, prompt: "Two people bake cookies together" },
  { id: 53, prompt: "One person wrapping a gift while the other holds it" },
  { id: 54, prompt: "Two people sharing a scarf in cold weather" },
  { id: 55, prompt: "One person helping the other adjust a belt" },
  { id: 56, prompt: "Two people share an umbrella" },
  { id: 57, prompt: "One person helping the other up from a chair" },
  { id: 58, prompt: "Two people wash a car together" },
  { id: 59, prompt: "One person putting on the other’s gloves" },
  { id: 60, prompt: "Two people holding hands while running" },
  { id: 61, prompt: "One person holding the other’s face with both hands" },
  { id: 62, prompt: "Two people be a three-legged race team" },
  { id: 63, prompt: "One person painting the other’s face" },
  { id: 64, prompt: "Two people share a giant sandwich" },
  { id: 65, prompt: "One person tickling the other’s sides" },
  { id: 66, prompt: "Two people sharing a towel after swimming" },
  { id: 67, prompt: "One person putting a hat on the other’s head" },
  { id: 68, prompt: "Two people holding onto each other while skating" },
  { id: 69, prompt: "One person feeding the other grapes" },
  { id: 70, prompt: "Two people trying to lift a heavy box together" },
  { id: 71, prompt: "One person brushing something off the other’s shirt" },
  { id: 72, prompt: "Two people sharing a milkshake with two straws" },
  { id: 73, prompt: "One person trying to help the other stand up" },
  { id: 74, prompt: "Two people trying to pop the same balloon" },
  { id: 75, prompt: "One person taking a photo of the other" },
  { id: 76, prompt: "Two people do push-ups together" },
  { id: 77, prompt: "One person feeding the other a donut" },
  { id: 78, prompt: "Two people trying to get into the same sleeping bag" },
  { id: 79, prompt: "One person wiping the other’s face" },
  { id: 80, prompt: "Two people paddle a canoe" },
  { id: 81, prompt: "One person helping the other put on a jacket" },
  { id: 82, prompt: "Two people sharing a large bowl of popcorn" },
  { id: 83, prompt: "One person pulling the other by the hand" },
  { id: 84, prompt: "Two people wrapping each other in Christmas lights" },
  { id: 85, prompt: "One person putting lotion on the other’s hands" },
  { id: 86, prompt: "Two people trying to blow up a giant beach ball" },
  { id: 87, prompt: "One person helping the other put on boots" },
  { id: 88, prompt: "Two people give each other a manicure" },
  { id: 89, prompt: "One person putting a scarf around the other’s neck" },
  { id: 90, prompt: "Two people sharing a pair of mittens" },

  { id: 91, prompt: "One person helping the other with a stuck zipper" },
  { id: 92, prompt: "Two people carry a heavy box together" },
  { id: 93, prompt: "One person draping a towel over the other" },
  { id: 94, prompt: "Two people tying each other’s aprons" },
  { id: 95, prompt: "One person cleaning the other’s glasses" },
  { id: 96, prompt: "Two people paddle a boat together" },
  { id: 97, prompt: "One person feeding the other an ice cream cone" },
  { id: 98, prompt: "Two people attempting to put on the same shirt" },
  { id: 99, prompt: "One person pulling the other onto a couch" },
  { id: 100, prompt: "Two people blow up balloons" },
  { id: 101, prompt: "One person brushing the other’s hair" },
  { id: 102, prompt: "Two people attempting to share a small blanket" },
  { id: 103, prompt: "One person giving the other a piggyback ride" },
  { id: 104, prompt: "Two people bake cookies together" },
  { id: 105, prompt: "One person feeding the other a popsicle" },
  { id: 106, prompt: "Two people trying to share one small chair" },
  { id: 107, prompt: "One person massaging the other’s shoulders" },
  { id: 108, prompt: "Two people wrapping each other in a large blanket" },
  { id: 109, prompt: "One person helping the other put on socks" },
  { id: 110, prompt: "Two people tying each other’s shoes" },
  { id: 111, prompt: "One person blowing a kiss to the other" },
  { id: 112, prompt: "Two people sharing a chocolate bar" },
  { id: 113, prompt: "One person tying a bow in the other’s hair" },
  { id: 114, prompt: "Two people lift weights together" },
  { id: 115, prompt: "One person pulling the other into a dance" },
  { id: 116, prompt: "Two people trying to squeeze into a photo booth" },
  { id: 117, prompt: "One person feeding the other a slice of pizza" },
  { id: 118, prompt: "Two people attempting to sit in a hammock together" },
  { id: 119, prompt: "One person putting a necklace on the other" },
  { id: 120, prompt: "Two people carry a couch together" },
  { id: 121, prompt: "One person wrapping a towel around the other" },
  { id: 122, prompt: "Two people sharing a bottle of soda with one straw" },
  { id: 123, prompt: "One person brushing crumbs off the other’s shirt" },
  { id: 124, prompt: "Two people bake a cake together" },
  { id: 125, prompt: "One person helping the other remove a ring" },
  { id: 126, prompt: "Two people playing with a jump rope together" },
  { id: 127, prompt: "One person putting a hat on the other’s head" },
  { id: 128, prompt: "Two people wrap a present together" },
  { id: 129, prompt: "One person holding the other’s face with both hands" },
  {
    id: 130,
    prompt: "Two people decorate a Christmas tree together",
  },
  { id: 131, prompt: "One person feeding the other grapes" },
  { id: 132, prompt: "Two people share a sleeping bag" },
  {
    id: 133,
    prompt: "One person blowing out a candle while the other watches",
  },
  { id: 134, prompt: "Two people trying to wrap a gift together" },
  { id: 135, prompt: "One person pulling the other into a tight embrace" },
  { id: 136, prompt: "Two people sit on a small bench together" },
  { id: 137, prompt: "One person putting a scarf around the other’s neck" },
  { id: 138, prompt: "Two people sharing a towel after a swim" },
  { id: 139, prompt: "One person wiping the other’s face with a napkin" },
  { id: 140, prompt: "Two people build a sandcastle together" },
  { id: 141, prompt: "One person wash the other’s hair" },
  { id: 142, prompt: "Two people row a boat together" },
  { id: 143, prompt: "One person feeding the other a donut" },
  { id: 144, prompt: "Two people set up a tent together" },
  { id: 145, prompt: "One person helping the other button a shirt" },
  {
    id: 146,
    prompt: "Two people share a giant ice cream sundae",
  },
  { id: 147, prompt: "One person blowing on the other’s food to cool it down" },
  { id: 148, prompt: "Two people attempting to tie a bow together" },
  { id: 149, prompt: "One person feeding the other an ice cream cone" },
  { id: 150, prompt: "Two people carry a large pizza together" },
  { id: 151, prompt: "One person tying a ribbon around the other’s wrist" },
  { id: 152, prompt: "Two people share a sleeping bag" },
  { id: 153, prompt: "One person wrapping the other in wrapping paper" },
  { id: 154, prompt: "Two people holding hands while walking" },
  { id: 155, prompt: "One person holding an umbrella over both" },
  { id: 156, prompt: "Two people sharing a bowl of popcorn" },
  { id: 157, prompt: "One person adjusting the other’s hat" },
  { id: 158, prompt: "Two people trying to share a hoodie" },
  { id: 159, prompt: "One person pulling the other into a tight hug" },
  { id: 160, prompt: "Two people sharing a single cupcake" },
  { id: 161, prompt: "One person feeding the other a chocolate bar" },
  { id: 162, prompt: "Two people attempting to sit in one chair together" },
  { id: 163, prompt: "One person pulling the other onto a couch" },
  { id: 164, prompt: "Two people carry a heavy box together" },
  { id: 165, prompt: "One person feeding the other grapes" },
  { id: 166, prompt: "Two people trying to get into the same sleeping bag" },
  { id: 167, prompt: "One person helping the other tie a tie" },
  { id: 168, prompt: "Two people holding hands while skipping" },
  { id: 169, prompt: "One person put a bracelet on the other" },
  { id: 170, prompt: "Two people wrapping a gift together" },
  { id: 171, prompt: "One person feeding the other chocolate" },
  { id: 172, prompt: "Two people set up a tent" },
  { id: 173, prompt: "One person feeding the other a popsicle" },
  { id: 174, prompt: "Two people sharing a scarf" },
  {
    id: 175,
    prompt: "One person blowing up a balloon while the other holds it",
  },
  { id: 176, prompt: "Two people attempting to share one large coat" },
  { id: 177, prompt: "One person helping the other take off a tight shirt" },
  { id: 178, prompt: "Two people sharing one chair" },
  { id: 179, prompt: "One person putting lotion on the other’s shoulders" },
  { id: 180, prompt: "Two people attempting to climb into a hammock together" },

  { id: 181, prompt: "Two people sharing a scarf" },
];
export default questionsArray;
