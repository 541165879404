import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Nav from "../components/Nav";

const getRandomNumber = (limit) => {
  const randomNum = Math.floor(Math.random() * limit);
  return randomNum;
};

function RealTalk({ version, cardData, cardIcon }) {
  const [currentPrompt, setCurrentPrompt] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState("#f6f6f6");
  const [theme, setTheme] = useState(version);

  function getRandomColor() {
    let defaultColors = ["#7BAFD4", "#A6B37D", "#FFB85C", "#B99470"];
    let cardColors = defaultColors;
    if (theme === "family") {
      // other colors = [
      //   "#3e6fc8",
      //   "#da619e",
      //   "#de2779",
      //   "#a83391",
      //   "#6dc0a3",
      //   "#6B8E23",
      //   "#7BAFD4",
      //   "#FF7F50",
      //   "#FF6347",
      // ];
    }

    if (theme == "couples") {
      // cardColors = [
      //   "#C5B358",
      //   "#2C2C2C",
      //   // "#F8F4E3",
      //   "#0A3D62",
      //   "#B76E79",
      //   "#B2BEB5",
      // ];

      cardColors = [
        "#8B5E83", // Deep Mauve
        "#D4A5A5", // Blush Pink
        "#5C3A4E", // Burgundy Wine
        "#262626", // black
        "#3A2C51", // Midnight Plum
        "#2C2C2C", // Pale Pink
      ];
    }

    const randomIndex = getRandomNumber(cardColors.length);

    setBackgroundColor(cardColors[randomIndex]);
  }

  const getRandomQuestion = () => {
    const randomIndex = getRandomNumber(cardData.length);
    getRandomColor();

    setCurrentPrompt(cardData[randomIndex]);
  };

  useEffect(() => {
    setTheme(version);

    getRandomQuestion();
  }, []);

  return (
    <>
      <Nav />
      <Container backgroundcolor={backgroundColor}>
        <div id="cards-wrapper" onClick={getRandomQuestion}>
          <div className="question-card">
            <img src={cardIcon} />
            <p>{currentPrompt ? currentPrompt.prompt : ""}</p>
            <p className="subject">{currentPrompt ? currentPrompt.type : ""}</p>
          </div>
        </div>
        <p id="version-id">Version: 5.0 - {version}</p>
      </Container>
    </>
  );
}

const Container = styled.div`
  padding-top: 100px;
  height: 100vh;
  background-color: #f2f2f2;
  padding: 60px 20px 0 20px;

  @media only screen and (max-width: 900px) {
    padding: 20px;
  }

  p {
    font-size: 16px;
    color: #333;
    margin-bottom: 20px;
  }

  #version-id {
    color: #b6b6b6;
    text-align: center;
  }

  button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: #0056b3;
    }
  }

  #cards-wrapper {
    background-color: ${({ backgroundcolor }) => `${backgroundcolor}`};
    border-radius: 50px;
    border: 12px solid #fff;
    width: 400px;
    max-width: 100%;
    margin: 40px auto;
    padding: 100px 40px;
  }

  .question-card {
    text-align: center;

    img {
      width: 80px;
      margin-bottom: 30px;
    }

    p {
      color: #fff;
      width: 500px;
      max-width: 100%;
      margin: 0 auto;
      font-size: 25px;
      min-height: 40px;
    }

    .subject {
      font-size: 14px;
      margin-top: 40px;
    }
  }
`;

export default RealTalk;
