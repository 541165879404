import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const theApiKey = process.env.REACT_APP_FIREBASE_API_KEY;
const theAuthDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
const theProjectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
const theStorageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
const theMessagingSenderId = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
const theAppId = process.env.REACT_APP_FIREBASE_APP_ID;

const firebaseConfig = {
  apiKey: theApiKey,
  authDomain: theAuthDomain,
  projectId: theProjectId,
  storageBucket: theStorageBucket,
  messagingSenderId: theMessagingSenderId,
  appId: theAppId,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
