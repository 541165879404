import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Home from "./pages/Home";
import CardsHome from "./pages/CardsHome";
import CardsPage from "./pages/CardsPage";
import Projects from "./pages/projects";
import FlashCards from "./pages/FlashCards";
import "./App.css"; // Import the CSS file
import ProtectedRoute from "./components/ProtectedRoute";

// Data Sources
import questionsCouplesVersion from "./real-talk-questions/data-couples-questions";
import questionsFamilyVersion from "./real-talk-questions/data-kids-questions";
import cardDeckTelestrationsAfterDark from "./data/data-card-deck-telestrations-after-dark";
import cardDeckCouplesPictionary from "./data/data-card-deck-couples-pictionary-after-dark";
import cardDeckNeverHaveIEverX from "./data/data-card-deck-never-have-i-ever-x";

// Assets
import cardIconFamily from "./assets/images/card-stack.svg";
import cardIconCouples from "./assets/images/card-stack-couples.svg";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route
          path="/cards/"
          exact={true}
          element={
            <ProtectedRoute>
              <CardsHome />
            </ProtectedRoute>
          }
        />
        <Route path="/math/" exact element={<FlashCards />} />
        <Route
          path="/projects/"
          exact={true}
          element={
            <ProtectedRoute>
              <Projects />
            </ProtectedRoute>
          }
        />
        <Route
          path="/cards/family"
          exact={true}
          element={
            <CardsPage
              version="family"
              cardIcon={cardIconFamily}
              cardData={questionsFamilyVersion}
            />
          }
        />
        <Route
          path="/cards/couples"
          exact={true}
          element={
            <ProtectedRoute>
              <CardsPage
                version="couples"
                cardIcon={cardIconCouples}
                cardData={questionsCouplesVersion}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/cards/telestrations-after-dark"
          exact={true}
          element={
            <CardsPage
              version="telestrations-after-dark"
              cardIcon={""}
              cardData={cardDeckTelestrationsAfterDark}
            />
          }
        />
        <Route
          path="/cards/couples-pictionary"
          exact={true}
          element={
            <CardsPage
              version="Couples Pictionary"
              cardIcon={""}
              cardData={cardDeckCouplesPictionary}
            />
          }
        />
        <Route
          path="/cards/never-have-i-ever/x"
          exact={true}
          element={
            <CardsPage
              version="Never Have I Ever"
              cardIcon={""}
              cardData={cardDeckNeverHaveIEverX}
            />
          }
        />
      </Routes>
    </div>
  );
}

export default App;
