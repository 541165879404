import React, { useState } from "react";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import styled from "styled-components";

function Login() {
  const [error, setError] = useState(null);
  const auth = getAuth();

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      setError(null); // Clear error on successful sign-in
    } catch (err) {
      setError("Failed to sign in with Google. Please try again.");
      console.error(err);
    }
  };

  return (
    <LoginContainer>
      <div className="box">
        <h2>Sign in for Access</h2>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <GoogleButton onClick={handleGoogleSignIn}>
          <img
            src="https://www.svgrepo.com/show/355037/google.svg"
            alt="Google Icon"
          />
          Sign in with Google
        </GoogleButton>
      </div>
    </LoginContainer>
  );
}

export default Login;

// Styled Components
const LoginContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .box {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    padding: 60px 40px;
    width: 300px;
    max-width: 100%;
  }

  h2 {
    text-align: center;
    margin-bottom: 30px;
  }
`;

const GoogleButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #4285f4;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);

  img {
    width: 20px;
    height: 20px;
  }

  &:hover {
    background-color: #357ae8;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: -10px;
  margin-bottom: 20px;
  font-size: 14px;
`;
