import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import metaImage from "../assets/images/games-meta.png";
import Nav from "../components/Nav";

const Container = styled.div`
  padding-top: 200px;
  height: 100vh;
  background-color: #fff;
  padding: 200px 20px 0 20px;
  text-align: center;

  @media only screen and (max-width: 900px) {
    padding: 50px 20px;
  }

  h1 {
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    color: #333;
    margin-bottom: 20px;
    line-height: 1.4;
  }

  ul {
    text-align: center;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 700px;
    max-width: 100%;
    margin: 20px auto 0 auto;
  }

  li {
    list-style: none;
    display: inline;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    background: #111;
    border-radius: 150px;
    padding: 8px 40px;
    text-align: center;
    margin: 10px;
    width: fit-content;

    a {
      text-decoration: none;
      color: #fff;
    }
  }
  button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: #0056b3;
    }
  }

  .question-card {
    width: 500px;
    max-width: 100%;
    background-color: #fff;
    border-radius: 15px;
    padding: 40px;
    margin: 40px 0;
    text-align: center;

    p {
      width: 500px;
      max-width: 100%;
      margin: 0 auto;
    }

    .subject {
      font-size: 14px;
      color: #666;
      margin-top: 40px;
    }
  }
`;

function RealTalk() {
  return (
    <>
      <Helmet>
        <title>Games Home</title>
        <meta name="description" content="Card Games" />
        <meta property="og:image" content={metaImage} />
      </Helmet>
      <Nav />
      <Container>
        <h1>Game Cards</h1>
        {/* <p>A way to get conversations going</p> */}
        <h3>
          <b>Topics:</b>
        </h3>
        <ul>
          <li>
            <a href="/cards/family">Kids & Family</a>
          </li>
          <li>
            <a href="/cards/couples">Couples</a>
          </li>
          <li>
            <a href="/cards/telestrations-after-dark">
              Telestrations After Dark
            </a>
          </li>
          <li>
            <a href="/cards/couples-pictionary">Couples Pictionary</a>
          </li>
          <li>
            <a href="/cards/never-have-i-ever/x">Never Have I Ever</a>
          </li>
        </ul>
      </Container>
    </>
  );
}

export default RealTalk;
