const questionsArray = [
  {
    id: 1,
    prompt: "If you could design a new playground, what would it look like?",
    type: "Kids & Family",
  },
  {
    id: 2,
    prompt: "What would you do if you found a magic door in our house?",
    type: "Kids & Family",
  },
  {
    id: 3,
    prompt:
      "If you could create your own ice cream flavor, what ingredients would you use?",
    type: "Kids & Family",
  },
  {
    id: 4,
    prompt:
      "What’s something you learned today that you didn’t know yesterday?",
    type: "Kids & Family",
  },
  {
    id: 5,
    prompt:
      "If you could have any animal (even a dinosaur) as a pet, which one would you choose and why?",
    type: "Kids & Family",
  },
  {
    id: 6,
    prompt:
      "How would you spend a million dollars if you could only use it for other people?",
    type: "Kids & Family",
  },
  {
    id: 7,
    prompt:
      "If you could make a rule for everyone in the world to follow, what would it be?",
    type: "Kids & Family",
  },
  {
    id: 8,
    prompt:
      "Imagine you could shrink down to the size of a bug—what would you explore first?",
    type: "Kids & Family",
  },
  {
    id: 9,
    prompt: "If you were in charge of dinner tonight, what would you cook?",
    type: "Kids & Family",
  },
  {
    type: "Kids & Family",
    id: 10,
    prompt: "What’s one thing you wish you could do every day?",
  },
  {
    id: 11,
    prompt: "If you could change one thing about our house, what would it be?",
    type: "Kids & Family",
  },
  {
    id: 12,
    prompt: "What would you do if you could be invisible for a day?",
    type: "Kids & Family",
  },
  {
    id: 13,
    prompt: "If you could live in any storybook, which one would you pick?",
    type: "Kids & Family",
  },
  {
    id: 14,
    prompt:
      "What’s one thing you’re really good at that you’d like to teach others?",
    type: "Kids & Family",
  },
  {
    id: 15,
    prompt:
      "Imagine you could fly—where would you go first and what would you do?",
    type: "Kids & Family",
  },
  {
    id: 16,
    prompt:
      "If you could trade places with one of your friends for a day, who would it be and why?",
    type: "Kids & Family",
  },
  {
    type: "Kids & Family",
    id: 17,
    prompt: "What’s the most interesting thing you’ve ever seen?",
  },
  {
    id: 18,
    prompt:
      "If you could invent a new holiday, what would it celebrate and how would we celebrate it?",
    type: "Kids & Family",
  },
  {
    id: 19,
    prompt:
      "What’s one thing that always makes you feel better when you’re upset?",
    type: "Kids & Family",
  },
  {
    id: 20,
    prompt:
      "If you could talk to animals, which animal would you talk to first, and what would you ask them?",
    type: "Kids & Family",
  },
  {
    id: 21,
    prompt: "What’s your favorite thing to do with your grandparents?",
    type: "Kids & Family",
  },
  {
    id: 22,
    prompt:
      "If you could plant a garden that grows anything in the world, what would you grow?",
    type: "Kids & Family",
  },
  {
    id: 23,
    prompt:
      "What’s one thing you would change about school to make it more fun?",
    type: "Kids & Family",
  },
  {
    id: 24,
    prompt:
      "If you could be the teacher for a day, what would you teach the class?",
    type: "Kids & Family",
  },
  {
    id: 25,
    prompt:
      "What’s the most exciting dream you’ve ever had, and what do you think it meant?",
    type: "Kids & Family",
  },
  {
    type: "Kids & Family",
    id: 26,
    prompt: "What was the best part of your day?",
  },
  {
    type: "Kids & Family",
    id: 27,
    prompt: "What’s your favorite color today?",
  },
  {
    type: "Kids & Family",
    id: 28,
    prompt: "If you could be any animal, what would you be?",
  },
  {
    type: "Kids & Family",
    id: 29,
    prompt: "What’s your favorite game to play?",
  },
  {
    type: "Kids & Family",
    id: 30,
    prompt: "If you could have any superpower, what would it be?",
  },
  {
    type: "Kids & Family",
    id: 31,
    prompt: "What’s your favorite thing to do outside?",
  },
  { type: "Kids & Family", id: 32, prompt: "What’s your favorite toy?" },
  {
    id: 33,
    prompt: "If you could go anywhere in the world, where would you go?",
    type: "Kids & Family",
  },
  { type: "Kids & Family", id: 34, prompt: "What’s your favorite book?" },
  {
    type: "Kids & Family",
    id: 35,
    prompt: "What’s the funniest thing that happened today?",
  },
  {
    type: "Kids & Family",
    id: 36,
    prompt: "If you could make up a new holiday, what would it be?",
  },
  {
    type: "Kids & Family",
    id: 37,
    prompt: "What do you like most about school?",
  },
  {
    type: "Kids & Family",
    id: 38,
    prompt: "Who is your best friend and why?",
  },
  {
    type: "Kids & Family",
    id: 39,
    prompt: "What’s your favorite kind of food?",
  },
  {
    type: "Kids & Family",
    id: 40,
    prompt: "What do you want to be when you grow up?",
  },
  {
    type: "Kids & Family",
    id: 41,
    prompt: "What’s your favorite subject at school?",
  },
  {
    id: 42,
    prompt: "If you could meet any cartoon character, who would it be?",
    type: "Kids & Family",
  },
  { type: "Kids & Family", id: 43, prompt: "What’s your favorite movie?" },
  {
    type: "Kids & Family",
    id: 44,
    prompt: "What’s your favorite thing to do with the family?",
  },
  {
    type: "Kids & Family",
    id: 45,
    prompt: "What’s your favorite thing about weekends?",
  },
  {
    type: "Kids & Family",
    id: 46,
    prompt: "If you could invent something, what would it be?",
  },
  {
    type: "Kids & Family",
    id: 47,
    prompt: "What’s the silliest thing you’ve ever done?",
  },
  {
    type: "Kids & Family",
    id: 48,
    prompt: "What do you like to do when it’s raining outside?",
  },
  { type: "Kids & Family", id: 49, prompt: "What makes you feel happy?" },
  {
    type: "Kids & Family",
    id: 50,
    prompt: "If you could change your name, what would you choose?",
  },
  { type: "Kids & Family", id: 51, prompt: "What’s your favorite dessert?" },
  {
    id: 52,
    prompt: "If you could have a superpower for one day, how would you use it?",
    type: "Kids & Family",
  },
  {
    id: 53,
    prompt: "What’s your favorite way to spend time with your siblings?",
    type: "Kids & Family",
  },
  {
    id: 54,
    prompt:
      "If you could build any kind of treehouse, what would it look like?",
    type: "Kids & Family",
  },
  {
    type: "Kids & Family",
    id: 55,
    prompt: "What’s the coolest thing you’ve ever seen in nature?",
  },
  {
    id: 56,
    prompt: "If you could have any job for one day, what would it be?",
    type: "Kids & Family",
  },
  {
    type: "Kids & Family",
    id: 57,
    prompt: "What’s your favorite memory from a family vacation?",
  },
  {
    id: 58,
    prompt: "If you could make your own theme park, what rides would it have?",
    type: "Kids & Family",
  },
  {
    id: 59,
    prompt: "What’s one thing you would do to make the world a better place?",
    type: "Kids & Family",
  },
  {
    id: 60,
    prompt: "If you could live in any movie, which one would it be?",
    type: "Kids & Family",
  },
  {
    type: "Kids & Family",
    id: 61,
    prompt: "What’s your favorite thing to do with your friends?",
  },
  {
    id: 62,
    prompt: "If you could be a character in any TV show, who would you be?",
    type: "Kids & Family",
  },
  {
    type: "Kids & Family",
    id: 63,
    prompt: "What’s your favorite way to spend a Saturday?",
  },
  {
    id: 64,
    prompt: "If you could live in any country, which one would you choose?",
    type: "Kids & Family",
  },
  {
    type: "Kids & Family",
    id: 65,
    prompt: "What’s the best gift you’ve ever received?",
  },
  {
    id: 66,
    prompt: "If you could invent a new type of car, what would it be like?",
    type: "Kids & Family",
  },
  {
    type: "Kids & Family",
    id: 67,
    prompt: "What’s your favorite thing to do in the summer?",
  },
  {
    type: "Kids & Family",
    id: 68,
    prompt: "If you could time travel, where would you go first?",
  },
  {
    type: "Kids & Family",
    id: 69,
    prompt: "What’s your favorite song to dance to?",
  },
  {
    id: 70,
    prompt: "If you could meet any historical figure, who would it be?",
    type: "Kids & Family",
  },
  {
    type: "Kids & Family",
    id: 71,
    prompt: "What’s your favorite season and why?",
  },
  {
    id: 72,
    prompt:
      "If you could be the principal of your school for a day, what changes would you make?",
    type: "Kids & Family",
  },
  {
    type: "Kids & Family",
    id: 73,
    prompt: "What’s the most delicious food you’ve ever eaten?",
  },
  {
    id: 74,
    prompt: "If you could have any imaginary friend, what would they be like?",
    type: "Kids & Family",
  },
  {
    type: "Kids & Family",
    id: 75,
    prompt: "What’s your favorite thing to do at the park?",
  },
  {
    id: 76,
    prompt:
      "If you could create your own holiday, what traditions would it have?",
    type: "Kids & Family",
  },
  {
    type: "Kids & Family",
    id: 77,
    prompt: "What’s the bravest thing you’ve ever done?",
  },
  {
    id: 78,
    prompt: "If you could be any mythical creature, what would you be?",
    type: "Kids & Family",
  },
  {
    type: "Kids & Family",
    id: 79,
    prompt: "What’s your favorite way to help others?",
  },
  {
    id: 80,
    prompt:
      "If you could create your own school, what subjects would you teach?",
    type: "Kids & Family",
  },
  {
    type: "Kids & Family",
    id: 81,
    prompt: "What’s the most interesting animal you’ve ever seen?",
  },
  {
    id: 82,
    prompt:
      "If you could switch lives with one of your favorite characters, who would it be?",
    type: "Kids & Family",
  },
  {
    id: 83,
    prompt: "What’s the most fun thing you’ve ever done with your family?",
    type: "Kids & Family",
  },
  {
    id: 84,
    prompt: "If you could have a party with any theme, what would it be?",
    type: "Kids & Family",
  },
  {
    type: "Kids & Family",
    id: 85,
    prompt: "What’s your favorite sport to play or watch?",
  },
  {
    type: "Kids & Family",
    id: 86,
    prompt: "If you could write a book, what would it be about?",
  },
  {
    type: "Kids & Family",
    id: 87,
    prompt: "What’s your favorite holiday and why?",
  },
  {
    id: 88,
    prompt: "If you could live in any time period, which one would you choose?",
    type: "Kids & Family",
  },
  {
    type: "Kids & Family",
    id: 89,
    prompt: "What’s the funniest joke you know?",
  },
  {
    id: 90,
    prompt: "If you could create a new animal, what would it look like?",
    type: "Kids & Family",
  },
  {
    type: "Kids & Family",
    id: 91,
    prompt: "What’s the most exciting thing you’ve ever done?",
  },
  {
    id: 92,
    prompt: "If you could be the ruler of a country, what laws would you make?",
    type: "Kids & Family",
  },
  {
    type: "Kids & Family",
    id: 93,
    prompt: "What’s your favorite thing to do in the winter?",
  },
  {
    id: 94,
    prompt:
      "If you could make up a new word, what would it be and what would it mean?",
    type: "Kids & Family",
  },
  {
    type: "Kids & Family",
    id: 95,
    prompt: "What’s your favorite thing to do on a rainy day?",
  },
  {
    id: 96,
    prompt:
      "If you could have a robot to do one job for you, what would it be?",
    type: "Kids & Family",
  },
  {
    type: "Kids & Family",
    id: 97,
    prompt: "What’s the most surprising thing you’ve ever learned?",
  },
  {
    id: 98,
    prompt: "If you could paint a giant mural, what would you paint?",
    type: "Kids & Family",
  },
  {
    type: "Kids & Family",
    id: 99,
    prompt: "What’s your favorite place in the whole world?",
  },
  {
    id: 100,
    prompt: "If you could change one thing about the world, what would it be?",
    type: "Kids & Family",
  },
];

export default questionsArray;
