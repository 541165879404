const questionsArray = [
  {
    id: 1,
    prompt: "What is your favorite memory of us together?",
    type: "Couples",
  },
  {
    id: 2,
    prompt: "What’s one thing you’ve always wanted to do together?",
    type: "Couples",
  },
  { id: 3, prompt: "What was your first impression of me?", type: "Couples" },
  {
    id: 4,
    prompt: "What is something that always makes you laugh?",
    type: "Couples",
  },
  {
    id: 5,
    prompt: "What is your favorite way to spend a weekend?",
    type: "Couples",
  },
  {
    id: 6,
    prompt: "What’s the most adventurous thing you’ve ever done?",
    type: "Couples",
  },
  {
    id: 7,
    prompt: "What’s your favorite meal that we’ve had together?",
    type: "Couples",
  },
  {
    id: 8,
    prompt: "If you could live anywhere in the world, where would it be?",
    type: "Couples",
  },
  {
    id: 9,
    prompt: "What’s your favorite way to relax after a long day?",
    type: "Couples",
  },
  {
    id: 10,
    prompt: "What do you think is the key to a successful relationship?",
    type: "Couples",
  },
  {
    id: 11,
    prompt: "What is something you’re really proud of?",
    type: "Couples",
  },
  {
    id: 12,
    prompt: "What’s your favorite movie to watch together?",
    type: "Couples",
  },
  {
    id: 13,
    question:
      "If you could relive any moment in our relationship, what would it be?",
    type: "Couples",
  },
  {
    id: 14,
    prompt: "What’s your idea of a perfect date night?",
    type: "Couples",
  },
  {
    id: 15,
    prompt: "What’s one place you’ve always wanted to visit?",
    type: "Couples",
  },
  {
    id: 16,
    prompt: "What’s your favorite thing about our relationship?",
    type: "Couples",
  },
  {
    id: 17,
    prompt: "What’s one thing you’ve learned from being in our relationship?",
    type: "Couples",
  },
  {
    id: 18,
    prompt: "What’s something you appreciate about me?",
    type: "Couples",
  },
  { id: 19, prompt: "What is your love language?", type: "Couples" },
  {
    id: 20,
    prompt: "What’s the best advice you’ve ever received?",
    type: "Couples",
  },
  {
    id: 21,
    prompt: "What’s your favorite hobby or activity to do together?",
    type: "Couples",
  },
  {
    id: 22,
    prompt: "What’s your dream vacation destination?",
    type: "Couples",
  },
  {
    id: 23,
    prompt: "What’s something you’ve never told anyone else?",
    type: "Couples",
  },
  {
    id: 24,
    prompt: "What’s your favorite thing to do on a rainy day?",
    type: "Couples",
  },
  {
    id: 25,
    prompt: "What’s one thing you would like to improve in our relationship?",
    type: "Couples",
  },
  {
    id: 26,
    prompt: "What’s your favorite way to be comforted when you’re upset?",
    type: "Couples",
  },
  {
    id: 27,
    prompt: "What do you think is your greatest strength?",
    type: "Couples",
  },
  {
    id: 28,
    question:
      "What’s one thing you’d like to try together that we’ve never done before?",
    type: "Couples",
  },
  {
    id: 29,
    prompt: "What’s your favorite memory from your childhood?",
    type: "Couples",
  },
  {
    id: 30,
    prompt: "What’s your favorite way to show affection?",
    type: "Couples",
  },
  { id: 31, prompt: "What’s your biggest fear?", type: "Couples" },
  {
    id: 32,
    prompt: "What’s something you’re really passionate about?",
    type: "Couples",
  },
  {
    id: 33,
    prompt: "What’s your favorite thing to do when you’re alone?",
    type: "Couples",
  },
  {
    id: 34,
    prompt: "What’s one thing you wish you could do more of?",
    type: "Couples",
  },
  {
    id: 35,
    prompt: "What’s your favorite time of year and why?",
    type: "Couples",
  },
  {
    id: 36,
    prompt: "What’s something you’ve always wanted to learn?",
    type: "Couples",
  },
  { id: 37, prompt: "What’s your idea of a perfect day?", type: "Couples" },
  {
    id: 38,
    prompt: "What’s the most meaningful gift you’ve ever received?",
    type: "Couples",
  },
  {
    id: 39,
    prompt: "What’s your favorite way to unwind after a stressful day?",
    type: "Couples",
  },
  {
    id: 40,
    prompt: "What’s something you’d like to achieve in the next year?",
    type: "Couples",
  },
  { id: 41, prompt: "What’s your favorite book?", type: "Couples" },
  {
    id: 42,
    prompt: "What’s your favorite holiday tradition?",
    type: "Couples",
  },
  {
    id: 43,
    prompt: "What’s the most surprising thing you’ve learned about yourself?",
    type: "Couples",
  },
  {
    id: 44,
    prompt: "What’s your favorite way to spend time outdoors?",
    type: "Couples",
  },
  {
    id: 45,
    prompt: "What’s one thing you admire about your family?",
    type: "Couples",
  },
  {
    id: 46,
    prompt: "What’s your favorite way to spend a lazy Sunday?",
    type: "Couples",
  },
  {
    id: 47,
    prompt: "What’s something you’d like to be better at?",
    type: "Couples",
  },
  {
    id: 48,
    prompt: "What’s your favorite memory of a holiday or special occasion?",
    type: "Couples",
  },
  {
    id: 49,
    question:
      "What’s something you’re looking forward to in the next few months?",
    type: "Couples",
  },
  {
    id: 50,
    prompt: "What’s your favorite way to stay active?",
    type: "Couples",
  },
  {
    id: 51,
    prompt: "What’s something that always makes you feel loved?",
    type: "Couples",
  },
  {
    id: 52,
    prompt: "What’s one thing you’d like to change about our routine?",
    type: "Couples",
  },
  { id: 53, prompt: "What’s your favorite type of music?", type: "Couples" },
  {
    id: 54,
    prompt: "What’s one thing you wish you could do every day?",
    type: "Couples",
  },
  {
    id: 55,
    prompt: "What’s something you’re grateful for today?",
    type: "Couples",
  },
  {
    id: 56,
    prompt: "What’s your favorite way to celebrate special occasions?",
    type: "Couples",
  },
  {
    id: 57,
    prompt: "What’s one thing you’ve always wanted to learn more about?",
    type: "Couples",
  },
  {
    id: 58,
    prompt: "What’s your favorite thing to cook or bake?",
    type: "Couples",
  },
  {
    id: 59,
    prompt: "What’s something that always makes you smile?",
    type: "Couples",
  },
  {
    id: 60,
    prompt: "What’s your favorite way to spend a Saturday morning?",
    type: "Couples",
  },
  {
    id: 61,
    prompt: "What’s one thing you’d like to improve in yourself?",
    type: "Couples",
  },
  {
    id: 62,
    prompt: "What’s your favorite way to relax with me?",
    type: "Couples",
  },
  {
    id: 63,
    prompt: "What’s one thing you love about your job?",
    type: "Couples",
  },
  {
    id: 64,
    prompt: "What’s your favorite thing about our home?",
    type: "Couples",
  },
  {
    id: 65,
    prompt: "What’s something you’d like to do together this weekend?",
    type: "Couples",
  },
  {
    id: 66,
    prompt: "What’s your favorite thing to watch on TV?",
    type: "Couples",
  },
  {
    id: 67,
    prompt: "What’s something you’ve always wanted to try?",
    type: "Couples",
  },
  {
    id: 68,
    prompt: "What’s your favorite memory of a trip we took?",
    type: "Couples",
  },
  {
    id: 69,
    prompt: "What’s one thing you’re really proud of in your life?",
    type: "Couples",
  },
  {
    id: 70,
    prompt: "What’s your favorite way to spend an evening together?",
    type: "Couples",
  },
  {
    id: 71,
    prompt: "What’s something you wish we could do more often?",
    type: "Couples",
  },
  { id: 72, prompt: "What’s your favorite comfort food?", type: "Couples" },
  {
    id: 73,
    prompt: "What’s something you’re excited about right now?",
    type: "Couples",
  },
  {
    id: 74,
    prompt: "What’s your favorite way to start the day?",
    type: "Couples",
  },
  {
    id: 75,
    prompt: "What’s one thing you wish you had more time for?",
    type: "Couples",
  },
  {
    id: 76,
    prompt: "What’s something you’d like to do differently this year?",
    type: "Couples",
  },
  { id: 77, prompt: "What’s your favorite place to relax?", type: "Couples" },
  {
    id: 78,
    prompt: "What’s one thing you’d like to accomplish this week?",
    type: "Couples",
  },
  {
    id: 79,
    prompt: "What’s your favorite way to be creative?",
    type: "Couples",
  },
  {
    id: 80,
    prompt: "What’s something you admire about me?",
    type: "Couples",
  },
  {
    id: 81,
    prompt: "What’s your favorite way to spend a quiet evening?",
    type: "Couples",
  },
  {
    id: 82,
    prompt: "What’s one thing you’d like to learn how to do?",
    type: "Couples",
  },
  {
    id: 83,
    prompt: "What’s your favorite type of weather?",
    type: "Couples",
  },
  {
    id: 84,
    prompt: "What’s something you’re really curious about?",
    type: "Couples",
  },
  {
    id: 85,
    prompt: "What’s one thing you’re really good at?",
    type: "Couples",
  },
  {
    id: 86,
    prompt: "What’s your favorite thing about the season we’re in?",
    type: "Couples",
  },
  {
    id: 87,
    prompt: "What’s something you’d like to experience together?",
    type: "Couples",
  },
  {
    id: 88,
    prompt: "What’s your favorite way to recharge your energy?",
    type: "Couples",
  },
  {
    id: 89,
    prompt: "What’s something you’ve learned recently?",
    type: "Couples",
  },
  {
    id: 90,
    prompt: "What’s your favorite thing about the weekend?",
    type: "Couples",
  },
  {
    id: 91,
    prompt: "What’s one thing you’d like to change about our relationship?",
    type: "Couples",
  },
  {
    id: 92,
    prompt: "What’s your favorite way to spend time with family?",
    type: "Couples",
  },
  {
    id: 93,
    prompt: "What’s something that makes you feel appreciated?",
    type: "Couples",
  },
  {
    id: 94,
    prompt: "What’s your favorite way to show kindness?",
    type: "Couples",
  },
  {
    id: 95,
    prompt: "What’s one thing you’d like to do more of this year?",
    type: "Couples",
  },
  {
    id: 96,
    prompt: "What’s your favorite way to celebrate achievements?",
    type: "Couples",
  },
  {
    id: 97,
    prompt: "What’s something you wish you did more often?",
    type: "Couples",
  },
  {
    id: 98,
    prompt: "What’s your favorite memory of us from this year?",
    type: "Couples",
  },
  {
    id: 99,
    prompt: "What’s one thing you’re looking forward to this month?",
    type: "Couples",
  },
  { id: 100, prompt: "What’s something you love about our life together?" },
];

export default questionsArray;
