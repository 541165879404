import React from "react";
import styled from "styled-components";
import GoogleAuth from "../components/GoogleAuth";
import Nav from "../components/Nav";
import { useAuth } from "../context/AuthContext";
import Login from "../components/Login";
import Dashboard from "../components/Dashboard";

function Home() {
  const { currentUser } = useAuth();

  return (
    <>
      {/* <Nav /> */}
      <Container>
        {!currentUser ? (
          <Login />
        ) : (
          <div>
            <Dashboard />
          </div>
        )}
      </Container>
    </>
  );
}

const Container = styled.div`
  h1 {
    color: #000;
    font-size: 40px;
  }
`;

export default Home;
