import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import styled from "styled-components";
import ical from "ical.js";

function WeekCalendarWidget() {
  const [events, setEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [weekEvents, setWeekEvents] = useState([]);
  const calURL = process.env.FAMILY_CAL_URL;

  // Fetch and parse iCal data directly in this component
  useEffect(() => {
    async function fetchICalData() {
      try {
        const response = await fetch("/api/fetch-calendar");

        const icalData = await response.text();

        const jcalData = ical.parse(icalData); // Parse iCal data
        const comp = new ical.Component(jcalData);
        const vevents = comp.getAllSubcomponents("vevent");

        // Extract relevant event details
        const parsedEvents = vevents.map((event) => {
          const summary = event.getFirstPropertyValue("summary");
          const startDate = event.getFirstPropertyValue("dtstart").toJSDate();
          const endDate = event.getFirstPropertyValue("dtend").toJSDate();
          return { summary, startDate, endDate };
        });

        setEvents(parsedEvents);
      } catch (error) {
        console.error("Error fetching iCal data:", error);
      }
    }

    fetchICalData();
  }, []);

  // Calculate the week’s start and end dates based on selectedDate
  const getWeekDates = (date) => {
    const startOfWeek = new Date(date);
    startOfWeek.setDate(date.getDate() - date.getDay()); // Start from Sunday
    return Array.from({ length: 7 }, (_, i) => {
      const day = new Date(startOfWeek);
      day.setDate(startOfWeek.getDate() + i);
      return day;
    });
  };

  // Filter events for the current week whenever selectedDate or events change
  useEffect(() => {
    const startOfWeek = new Date(selectedDate);
    startOfWeek.setDate(selectedDate.getDate() - selectedDate.getDay()); // Start from Sunday
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6); // End on Saturday

    const filteredEvents = events.filter(
      (event) => event.startDate >= startOfWeek && event.startDate <= endOfWeek
    );
    setWeekEvents(filteredEvents);
  }, [selectedDate, events]);

  // Render each day’s events
  const renderEventsForDay = (date) => {
    const dayEvents = weekEvents.filter(
      (event) => event.startDate.toDateString() === date.toDateString()
    );
    return (
      <div className="day-events">
        <h3>{date.toDateString()}</h3>
        {dayEvents.length > 0 ? (
          dayEvents.map((event, index) => (
            <Event key={index}>
              <h4>{event.summary}</h4>
              <p>
                {event.startDate.toLocaleTimeString()} -{" "}
                {event.endDate.toLocaleTimeString()}
              </p>
            </Event>
          ))
        ) : (
          <p>No events</p>
        )}
      </div>
    );
  };

  const weekDates = getWeekDates(selectedDate);

  return (
    <CalendarWrap>
      {/* <Calendar onChange={setSelectedDate} value={selectedDate} /> */}
      <WeekView>
        {weekDates.map((date, index) => (
          <DayColumn key={index}>{renderEventsForDay(date)}</DayColumn>
        ))}
      </WeekView>
    </CalendarWrap>
  );
}

export default WeekCalendarWidget;

// Styled Components

const CalendarWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
`;

const WeekView = styled.div`
  display: flex;
  width: 100%;
  max-width: 800px;
  gap: 1rem;
  margin-top: 20px;
`;

const DayColumn = styled.div`
  flex: 1;
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

  h3 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
`;

const Event = styled.div`
  background-color: #e3f2fd;
  padding: 5px;
  border-radius: 4px;
  margin-bottom: 5px;

  h4 {
    font-size: 0.9rem;
    margin: 0;
    color: #333;
  }

  p {
    font-size: 0.8rem;
    color: #666;
    margin: 2px 0 0;
  }
`;
