// src/components/Navbar.js
import React from "react";
import { Link } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import styled from "styled-components";
import { useAuth } from "../context/AuthContext";

function Nav() {
  const { currentUser } = useAuth();

  return (
    <NavigationWrap className={`${!currentUser ? "hide" : "show"}`}>
      <div className="logo">
        <Link to="/" className="nav-link">
          Family Dashboard
        </Link>
      </div>
      <div className="nav-links">
        <Link to="/cards" className="nav-link">
          Games
        </Link>
        <Link to="/math" className="nav-link">
          Learn
        </Link>
        {/* <Link to="/contact" className="nav-link">
          Contact
        </Link> */}
      </div>
      <div className="profile-icon">
        {currentUser && currentUser.photoURL ? (
          <Link to="/" className="nav-link">
            <img
              src={currentUser.photoURL}
              alt="Profile"
              className="profile-image"
            />
          </Link>
        ) : (
          <Link to="/" className="nav-link">
            <FaUserCircle size={30} className="profile-image" />
          </Link>
        )}
      </div>
    </NavigationWrap>
  );
}

// Styled Component for the entire navigation bar
const NavigationWrap = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #333;
  color: white;

  &.hide {
    display: none;
  }

  .logo {
    font-size: 1.5rem;
    font-weight: bold;

    @media only screen and (max-width: 600px) {
      display: none;
    }
  }

  .nav-links {
    display: flex;
    gap: 1rem;
  }

  .nav-link {
    color: white;
    text-decoration: none;
    font-size: 1rem;

    &:hover {
      text-decoration: underline;
    }
  }

  .profile-image {
    display: flex;
    align-items: center;
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
`;

export default Nav;
