const questionsArray = [
  { id: 1, prompt: "Never have I ever sent a nude photo" },
  { id: 2, prompt: "Never have I ever had a one-night stand" },
  { id: 3, prompt: "Never have I ever hooked up with someone I just met" },
  { id: 4, prompt: "Never have I ever kissed someone of the same sex" },
  { id: 5, prompt: "Never have I ever been caught naked" },
  { id: 6, prompt: "Never have I ever lied about my number of partners" },
  { id: 7, prompt: "Never have I ever had a friend with benefits" },
  { id: 8, prompt: "Never have I ever flirted with someone who was taken" },
  { id: 9, prompt: "Never have I ever had a threesome" },
  { id: 10, prompt: "Never have I ever hooked up with a coworker" },
  { id: 11, prompt: "Never have I ever gone skinny dipping with someone else" },
  { id: 12, prompt: "Never have I ever faked an orgasm" },
  { id: 13, prompt: "Never have I ever hooked up with an ex" },
  { id: 14, prompt: "Never have I ever kissed more than one person in a day" },
  { id: 15, prompt: "Never have I ever had sex in a public place" },
  { id: 16, prompt: "Never have I ever had a crush on a friend’s partner" },
  { id: 17, prompt: "Never have I ever used Tinder or a similar app" },
  { id: 18, prompt: "Never have I ever slept with someone twice my age" },
  { id: 19, prompt: "Never have I ever had a secret relationship" },
  { id: 20, prompt: "Never have I ever been walked in on during sex" },
  {
    id: 21,
    prompt: "Never have I ever kissed someone in the backseat of a car",
  },
  { id: 22, prompt: "Never have I ever made out with a stranger" },
  { id: 23, prompt: "Never have I ever gone home with someone I just met" },
  { id: 24, prompt: "Never have I ever been to a strip club" },
  { id: 25, prompt: "Never have I ever sexted someone" },
  { id: 26, prompt: "Never have I ever kissed someone while drunk" },
  {
    id: 27,
    prompt: "Never have I ever hooked up with someone and forgotten their name",
  },
  { id: 28, prompt: "Never have I ever had sex on the first date" },
  { id: 29, prompt: "Never have I ever lied about being single" },
  { id: 30, prompt: "Never have I ever been kicked out of a bar or club" },
  { id: 31, prompt: "Never have I ever kissed someone else's partner" },
  { id: 32, prompt: "Never have I ever had sex in a car" },
  { id: 33, prompt: "Never have I ever sent a dirty text to the wrong person" },
  { id: 34, prompt: "Never have I ever had a crush on my boss" },
  { id: 35, prompt: "Never have I ever slept with someone on the first date" },
  { id: 36, prompt: "Never have I ever lied about my sexual experience" },
  { id: 37, prompt: "Never have I ever been in an open relationship" },
  { id: 38, prompt: "Never have I ever used a dating app to hook up" },
  { id: 39, prompt: "Never have I ever had sex in a hotel room" },
  { id: 40, prompt: "Never have I ever had a sex dream about a friend" },
  { id: 41, prompt: "Never have I ever kissed someone in the rain" },
  { id: 42, prompt: "Never have I ever taken a sexy selfie" },
  { id: 43, prompt: "Never have I ever hooked up with someone in a bathroom" },
  { id: 44, prompt: "Never have I ever had a crush on a teacher" },
  { id: 45, prompt: "Never have I ever kissed someone at work" },
  { id: 46, prompt: "Never have I ever lied about my sexual preferences" },
  {
    id: 47,
    prompt: "Never have I ever hooked up with more than one person in 24 hours",
  },
  { id: 48, prompt: "Never have I ever had sex in the shower" },
  { id: 49, prompt: "Never have I ever had a crush on a friend’s sibling" },
  {
    id: 50,
    prompt:
      "Never have I ever lied about liking someone to avoid hurting their feelings",
  },

  {
    id: 51,
    prompt: "Never have I ever hooked up with someone while on vacation",
  },
  { id: 52, prompt: "Never have I ever participated in role-playing" },
  { id: 53, prompt: "Never have I ever made a sex tape" },
  {
    id: 54,
    prompt: "Never have I ever hooked up with someone in a friend's bed",
  },
  { id: 55, prompt: "Never have I ever had sex in a hot tub" },
  {
    id: 56,
    prompt:
      "Never have I ever hooked up with more than one person in the same day",
  },
  { id: 57, prompt: "Never have I ever been caught hooking up in public" },
  { id: 58, prompt: "Never have I ever had a crush on a friend’s parent" },
  {
    id: 59,
    prompt:
      "Never have I ever hooked up with someone while their partner was nearby",
  },
  { id: 60, prompt: "Never have I ever used a toy during sex" },
  { id: 61, prompt: "Never have I ever been involved in a love triangle" },
  { id: 62, prompt: "Never have I ever had a friend walk in during sex" },
  { id: 63, prompt: "Never have I ever been to a swingers’ party" },
  {
    id: 64,
    prompt: "Never have I ever hooked up with someone in a dressing room",
  },
  { id: 65, prompt: "Never have I ever had sex in the ocean" },
  { id: 66, prompt: "Never have I ever participated in a strip game" },
  { id: 67, prompt: "Never have I ever had sex in an elevator" },
  { id: 68, prompt: "Never have I ever kissed someone in a dark alley" },
  { id: 69, prompt: "Never have I ever made out with someone’s partner" },
  { id: 70, prompt: "Never have I ever had sex outdoors" },
  {
    id: 71,
    prompt:
      "Never have I ever hooked up with someone in the same room as someone else",
  },
  { id: 72, prompt: "Never have I ever done a body shot" },
  { id: 73, prompt: "Never have I ever hooked up with someone at a wedding" },
  { id: 74, prompt: "Never have I ever had sex in a club" },
  { id: 75, prompt: "Never have I ever sexted with someone else in the room" },
  { id: 76, prompt: "Never have I ever been with someone for their money" },
  { id: 77, prompt: "Never have I ever had a crush on my best friend" },
  { id: 78, prompt: "Never have I ever been handcuffed to a bed" },
  {
    id: 79,
    prompt:
      "Never have I ever hooked up with someone while drunk and regretted it",
  },
  { id: 80, prompt: "Never have I ever been in a relationship just for sex" },
  { id: 81, prompt: "Never have I ever flashed someone" },
  {
    id: 82,
    prompt: "Never have I ever hooked up with someone in a movie theater",
  },
  { id: 83, prompt: "Never have I ever slept with someone and ghosted them" },
  { id: 84, prompt: "Never have I ever hooked up with someone twice my age" },
  { id: 85, prompt: "Never have I ever been involved in BDSM" },
  { id: 86, prompt: "Never have I ever hooked up with someone from work" },
  { id: 87, prompt: "Never have I ever had a secret fling" },
  {
    id: 88,
    prompt: "Never have I ever hooked up with someone I shouldn’t have",
  },
  { id: 89, prompt: "Never have I ever been walked in on while hooking up" },
  { id: 90, prompt: "Never have I ever hooked up with someone in a car" },
  {
    id: 91,
    prompt: "Never have I ever kissed someone to make someone else jealous",
  },
  {
    id: 92,
    prompt: "Never have I ever lied about how good someone was in bed",
  },
  { id: 93, prompt: "Never have I ever had sex on a first date" },
  {
    id: 94,
    prompt: "Never have I ever hooked up with someone just because I was bored",
  },
  {
    id: 95,
    prompt:
      "Never have I ever made out with someone just to make someone jealous",
  },
  { id: 96, prompt: "Never have I ever kissed someone in a pool" },
  { id: 97, prompt: "Never have I ever been to a strip club with my partner" },
  {
    id: 98,
    prompt: "Never have I ever hooked up with someone in a public bathroom",
  },
  { id: 99, prompt: "Never have I ever lied to my partner about where I was" },
  {
    id: 100,
    prompt: "Never have I ever had a one-night stand and kept in touch",
  },
];

export default questionsArray;
