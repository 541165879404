import React, { useState, useEffect } from "react";
import styled from "styled-components";

function Todo() {
  const [tasks, setTasks] = useState([]);
  const [input, setInput] = useState("");

  // Load tasks from localStorage when component mounts
  useEffect(() => {
    const savedTasks = JSON.parse(localStorage.getItem("tasks")) || [];
    setTasks(savedTasks);
  }, []);

  // Save tasks to localStorage whenever tasks change
  useEffect(() => {
    localStorage.setItem("tasks", JSON.stringify(tasks));
  }, [tasks]);

  const addTask = () => {
    if (input.trim()) {
      setTasks([...tasks, input.trim()]);
      setInput("");
    }
  };

  const removeTask = (index) => {
    const updatedTasks = tasks.filter((_, i) => i !== index);
    setTasks(updatedTasks);
  };

  return (
    <Widget>
      <h3>My List</h3>
      <ul className="task-list">
        {tasks.map((task, index) => (
          <li key={index} className="task-item">
            <span>{task}</span>
            <button onClick={() => removeTask(index)}>X</button>
          </li>
        ))}
      </ul>
      <div className="input-container">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Add a task"
        />
        <button onClick={addTask}>Add</button>
      </div>
    </Widget>
  );
}

const Widget = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;

  h3 {
    text-align: center;
    margin-bottom: 15px;
  }

  .input-container {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;

    input {
      flex: 1;
      padding: 8px;
      border-radius: 4px;
      border: 1px solid #ddd;
    }

    button {
      padding: 8px 12px;
      background-color: #0077cc;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: #005fa3;
      }
    }
  }

  .task-list {
    list-style-type: none;
    padding: 0;
    margin: 0;

    .task-item {
      display: flex;
      justify-content: space-between;
      padding: 8px;
      background-color: #f7f7f7;
      border-radius: 4px;
      margin-bottom: 8px;

      button {
        background-color: #ff6347;
        border: none;
        color: #fff;
        padding: 4px 8px;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background-color: #e5533d;
        }
      }
    }
  }
`;
export default Todo;
