// src/pages/Dashboard.js
import React from "react";
import styled from "styled-components";
import { FaUserCircle } from "react-icons/fa";
import { useAuth } from "../context/AuthContext";
import CalendarWidget from "./CalendarWidget";
import BasicCalendar from "./Calendar";
import Nav from "./Nav";
import Todo from "./Todo";

function Dashboard() {
  const { currentUser } = useAuth();
  return (
    <>
      <Nav />
      <DashboardWrap>
        {/* <Sidebar>
        <h2>Family Hub</h2>
        <ul>
        <li>Home</li>
        <li>Games</li>
        <li>Learn</li>
        <li>Calendar</li>
        </ul>
        </Sidebar> */}

        {/* <Header>
            <h1>Welcome {currentUser.displayName}</h1>
            <div className="profile">
              {currentUser && currentUser.photoURL ? (
                <img src={currentUser.photoURL} alt="Profile" />
              ) : (
                <FaUserCircle size={30} className="profile-image" />
              )}
            </div>
          </Header> */}

        <MainContent>
          {/* <CalendarWidget /> */}
          <div className="tile">
            <BasicCalendar />
          </div>
          <div className="tile">
            <Todo />
          </div>
        </MainContent>
        <Footer>{/* <p>© 2024 MyApp. All rights reserved.</p> */}</Footer>
      </DashboardWrap>
    </>
  );
}

export default Dashboard;

// Styled Components

const DashboardWrap = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;

  @media only screen and (max-width: 850px) {
    height: auto;
    display: inline;
  }
  .tile {
    background: #fff;
    width: fit-content;
    padding: 40px;
    border-radius: 15px;

    @media only screen and (max-width: 850px) {
      padding: 30px;
      margin: 0 auto;
    }
  }
`;

const Sidebar = styled.div`
  width: 250px;
  background-color: #333;
  color: white;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
  }

  ul {
    list-style: none;
    padding: 0;
    width: 100%;
  }

  li {
    margin: 1rem 0;
    cursor: pointer;
    padding: 0.5rem 0;
    text-align: center;
    border-radius: 4px;

    &:hover {
      background-color: #444;
    }
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  h1 {
    font-size: 1.5rem;
  }

  .profile img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
  }
`;

const MainContent = styled.main`
  width: 100%;
  padding: 40px;
  background-color: #f6f6f6;
  display: grid;
  grid-template-columns: 400px 1fr;
  grid-template-rows: minmax(300px, 500px);
  height: 100vh;

  @media only screen and (max-width: 900px) {
    padding: 40px 0;
  }
  @media only screen and (max-width: 700px) {
    grid-template-columns: 1fr;
    row-gap: 20px;
    padding: 30px 0 50px 0;
    grid-template-rows: initial;
    height: initial;
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
    color: #666;
  }
`;

const Footer = styled.footer`
  display: none;
  padding: 1rem;
  text-align: center;
  background-color: #fff;
  /* box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1); */

  p {
    margin: 0;
    font-size: 0.9rem;
    color: #999;
  }
`;
